// i18nLocalesOverride:['es-CL']

const React = require('react');

const Image = require('nordic/image');

const { formatterPrice } = require('../../../../../../services/utils/formatter');
const { getBetterActivationPrize } = require('../../../../../../services/utils/prizes');

module.exports = (groupConfigs, devicesConfig) => {
  const purchasesPrize = formatterPrice(+groupConfigs?.frequent_purchases_earning_amount || 0);

  const purchasesQuantity = groupConfigs?.frequent_purchases_device_quantity || 0;

  const activationPrize = formatterPrice(+getBetterActivationPrize(devicesConfig?.configs) || 0);

  return {
    title: 'Conoce más sobre lo que puedes ganar',
    prizes: [
      {
        type: 'benefit',
        icon: <Image src="point-usage-2.png" />,
        title: 'Margen de venta',
        description: 'Gana por la venta de cada lector.',
        secondDescription: '¡Tú eliges en qué monto venderlo!',
      },
      {
        type: 'benefit',
        icon: <Image src="mlc-frequency-prize.png" />,
        title: 'Premio por compras frecuentes',
        description: `Gana ${purchasesPrize} cada mes, al comprar ${purchasesQuantity} lectores mensualmente, durante dos meses consecutivos.`,
      },
      {
        type: 'benefit',
        icon: <Image src="benefit-mlc-activation.png" />,
        title: 'Premio por activación',
        description: `Gana hasta ${activationPrize} por cada lector que logre cobrar un monto mínimo mensual establecido.`,
      },
    ],
  };
};
