// i18nLocalesOverride:['es-AR','es-MX','es-CL']

module.exports = (siteId) => {
  if (siteId === 'MLC') {
    return {
      title: 'Compra a precios exclusivos',
      text: 'Vende online o de forma presencial y recibe excelentes ingresos por cada venta.',
    };
  }

  if (siteId === 'MLM') {
    return {
      title: 'Compra con beneficios',
      text: 'Inversión mínima de 5 Point Blue.',
    };
  }

  return {
    title: 'Comprá con beneficios exclusivos',
    text: 'Vendé online o de forma presencial y generá ingresos por margen de venta.',
  };
};
