// i18nLocalesOverride:['es-AR','es-MX','es-CL','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const injectI18n = require('nordic/i18n/injectI18n');
const { Typography } = require('@andes/typography');

const CardDivider = require('../../../Atoms/CardDivider');
const Collapsible = require('../../../Molecules/Collapsible');
const tracking = require('../../../../../services/utils/tracking');

const faqsConfigs = require('./configs');

const FAQList = ({
  i18n,
  configs = {
    devices: [],
    prizesConfigs: [],
  },
  devices = [],
  siteId = 'MLA',
}) => {
  const config = faqsConfigs(i18n, devices, siteId, configs);

  const isCollapsible = siteId === 'MLC' || siteId === 'MLB';

  const [showAllFaqs, setShowAllFaqs] = React.useState(!isCollapsible);
  const [faqs, setFaqs] = React.useState(isCollapsible ? config.faqs.slice(0, 5) : config.faqs);

  const handleRedirectToForm = () => {
    const { deviceType } = typeof window !== 'undefined' && window.__PRELOADED_STATE__;

    const findFormRedirectElement = document.getElementById('form-redirect');

    findFormRedirectElement?.addEventListener('click', () =>
      window.scrollTo(0, deviceType !== 'mobile' ? 550 : 750),
    );
  };

  const handleChangeFaqQuantity = (state) => {
    if (state) {
      setFaqs(config.faqs);
    } else {
      setFaqs(config?.faqs?.slice(0, 5));
    }

    tracking('home', `faq-${state ? 'see-more' : 'see-less'}-button`);

    setShowAllFaqs(state);
  };

  const buttonLabel = {
    MLC: showAllFaqs ? 'Ver menos' : 'Ver más',
    MLB: showAllFaqs ? i18n.gettext('Ocultar preguntas') : i18n.gettext('Cargar más'),
  };

  return (
    <div className="faq-list">
      <CardDivider />
      <Typography component="h2" size="l" type="title">
        {config.title}
      </Typography>

      <Collapsible items={faqs} onclick={handleRedirectToForm} />

      {isCollapsible && (
        <div className="buttons">
          <div
            className="faq-list__button"
            role="button"
            tabIndex={-1}
            onClick={() => handleChangeFaqQuantity(!showAllFaqs)}
            onKeyDown={() => {}}
          >
            {buttonLabel[siteId || 'MLB']}
          </div>
        </div>
      )}
    </div>
  );
};

FAQList.propTypes = {
  configs: PropTypes.shape({
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        model: PropTypes.string,
        price: PropTypes.number,
        suggested_sale_price: PropTypes.number,
      }),
    ),
    prizesConfigs: PropTypes.arrayOf(
      PropTypes.shape({
        group_name: PropTypes.string,
        group_id: PropTypes.number,
        prizes: PropTypes.shape({
          device_activation_max_earning: PropTypes.number,
          new_level_bonus_amount: PropTypes.number,
          device_activation_earning_amount: PropTypes.number,
          tpv_percentage_earn_prize: PropTypes.number,
          tpv_max_earn_prize: PropTypes.number,
          max_prize_amount_per_month: PropTypes.number,
        }),
      }),
    ),
  }),
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({
      gettext: PropTypes.func,
    }),
  }).isRequired,
  siteId: PropTypes.string,
};

module.exports = injectI18n(FAQList);
