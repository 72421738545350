/* eslint-disable no-case-declarations */
/* eslint-disable import/order */

const React = require('react');
const PropTypes = require('prop-types');

const { useEffect, useState } = React;

const { resendConfirmationEmail } = require('../../../../../../services/leads');
const { saveLeads } = require('../../../../../../services/selfpurchase');

const { getExternalURLs } = require('../../../../../../services/utils/navigation');
const tracking = require('../../../../../../services/utils/tracking');

const API_RESPONSE_TYPE = require('../constants/apiResponseType');
const LEAD_STATUS = require('../../../../../../services/constants/leadStatus');

const COUNTER_TIME = 30;

const useForm = (props) => {
  const {
    apiResponseType,
    errors,
    onSetApiResponseType,
    onSetErrors,
    onSetShowErrors,
    siteId,
    traficOrigin,
    user,
  } = props;

  const [counter, setCounter] = useState(COUNTER_TIME);
  const [leadId, setLeadId] = useState('');
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [customerId, setCustomerId] = useState('');

  const externalUrls = getExternalURLs(siteId, traficOrigin);

  const trackingEvent = (event) => {
    tracking('home', event);
  };

  const resendEmail = async (id) => {
    try {
      await resendConfirmationEmail(false, leadId || id);

      setCounter(COUNTER_TIME);

      onSetApiResponseType(API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT_RESEND_EMAIL);
    } catch (err) {
      onSetApiResponseType(API_RESPONSE_TYPE.ERROR);
    }
  };

  const handleSaveRegisterResponse = (response) => {
    const { has_mp_account, is_new, is_reseller, status, customer_id } = response;

    setCustomerId(customer_id);

    switch (true) {
      case !is_new && status === LEAD_STATUS.USER_PENDING_CONFIRMATION_BY_EMAIL:
        trackingEvent('new_register_with_user_pending_confirmation_email');

        resendEmail(response?.id);

        return;

      case !is_new && status === LEAD_STATUS.USER_PENDING_CONFIRMATION:
        trackingEvent('new_register_with_user_pending_confirmation');

        onSetApiResponseType(API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT);

        return;

      case is_reseller:
        trackingEvent('user_is_reseller_but_not_logged');

        onSetApiResponseType(API_RESPONSE_TYPE.ERROR_ACCOUNT_DUPLICATED);

        return;

      case user.logged:
        trackingEvent('new_register_with_mp_account_already_created');

        const custIdParam = `cust_id=${customer_id}`;

        let baseUrl = externalUrls.ACCOUNT_CONFIRMATION;

        baseUrl += baseUrl.includes('?') ? `&${custIdParam}` : `?${custIdParam}`;

        window.open(baseUrl, '_self');

        return;

      case has_mp_account:
        tracking('home', 'new_register_with_mp_account_already_created');

        onSetApiResponseType(API_RESPONSE_TYPE.SUCCESS_WITH_ACCOUNT);

        return;

      default:
        trackingEvent('new_register_with_new_account');

        onSetApiResponseType(API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT);
    }
  };

  const saveRegister = async (token) => {
    try {
      const { name, email, phone: phoneNumber } = user;

      const phone = phoneNumber?.replace(/\D+/g, '');

      const userPayload = {
        name,
        email,
        phone,
        trafic_origin: traficOrigin,
        recaptcha_token: token,
        phone_details: {
          area_code: phone?.slice(0, 2),
          number: phone?.slice(2),
        },
      };

      const resp = await saveLeads(userPayload);

      setLeadId(resp?.data?.id);

      handleSaveRegisterResponse(resp?.data);
    } catch (error) {
      tracking('home', 'new_register_with_error');

      onSetApiResponseType(API_RESPONSE_TYPE.ERROR);
    } finally {
      const userPayload = {
        name: user.name,
        email: user.email,
        phone: user.phone,
      };

      tracking('home', `resellers_leads_token_${JSON.stringify(userPayload)}`);
    }
  };

  const handleFieldWithErrors = () => {
    const { email, name, phone, tyc } = user;

    const listErrors = errors;

    if (!email) {
      listErrors.push('MAIL');
    }

    if (!name) {
      listErrors.push('NAME');
    }

    if (!phone) {
      listErrors.push('PHONE');
    }

    if (!tyc) {
      listErrors.push('TYC');
    }

    onSetShowErrors(true);

    onSetErrors(listErrors);

    return listErrors.length === 0;
  };

  const validateForm = async (e) => {
    e.preventDefault();

    const tokenPosition = user?.logged ? 3 : 5;
    const token = recaptchaToken || e.target[tokenPosition]?.value;

    const formIsValid = handleFieldWithErrors();

    if (!formIsValid || !token) {
      trackingEvent('try_register_with_invalid_form');

      onSetShowErrors(true);

      setRecaptchaError(!token);

      return;
    }

    await saveRegister(token);

    setRecaptchaToken(token);

    setRecaptchaError(false);

    onSetShowErrors(false);
  };

  useEffect(() => {
    const showCounter = [
      API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT,
      API_RESPONSE_TYPE.SUCCESS_WITHOUT_ACCOUNT_RESEND_EMAIL,
    ].includes(apiResponseType);

    if (!showCounter) {
      return () => {};
    }

    const timer = setInterval(
      () =>
        setCounter((state) => {
          const newState = state - 1;

          return String(newState).padStart(2, '0');
        }),
      1000,
    );

    return () => clearInterval(timer);
  }, [apiResponseType]);

  return {
    counter,
    customerId,
    onResendEmail: resendEmail,
    recaptchaError,
    validateForm,
  };
};

useForm.propTypes = {
  apiResponseType: PropTypes.string,
  onSetApiResponseType: PropTypes.func,
  onSetErrors: PropTypes.func,
  onSetShowErrors: PropTypes.func,
  siteId: PropTypes.string,
  traficOrigin: PropTypes.string,
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    tyc: PropTypes.bool,
    logged: PropTypes.bool,
  }),
};

module.exports = useForm;
