// i18nLocalesOverride:['es-AR','es-MX','es-CL']

module.exports = (siteId) => {
  if (siteId === 'MLC') {
    return {
      title: 'Conoce cómo funciona el Programa de Revendedores',
      steps: [
        {
          id: 1,
          image: 'step-one.png',
          description: `<strong> Inscríbete <a href="#form">completando el formulario</a></strong> e inicia tu proceso de registro en el programa.`,
        },
        {
          id: 2,
          image: 'step-two.png',
          description:
            '<strong>Compra</strong> a precio preferencial y además recibe los lectores con envío gratis.',
        },
        {
          id: 3,
          image: 'step-three.png',
          description:
            '<strong>Vende</strong> los lectores, gana por cada venta y por los premios.',
        },
      ],
    };
  }

  if (siteId === 'MLM') {
    return {
      title: 'Conoce cómo funciona el Programa de Representantes',
      steps: [
        {
          id: 1,
          image: 'step-one.png',
          title: 'Compra con descuento',
          description: 'Accede a todos los modelos con precio de mayoreo y envío gratis.',
        },
        {
          id: 2,
          image: 'step-two.png',
          title: 'Obtén ganancias por venta',
          description: 'Vende las terminales a precio sugerido y obtén un margen de ganancias.',
        },
        {
          id: 3,
          image: 'step-three.png',
          title: 'Gana premios por tu desempeño',
          description: 'Alcanza metas por desempeño y gana dinero con los premios del programa.',
        },
      ],
    };
  }

  return {
    title: 'Conoce cómo funciona el Programa de Revendedores',
    subtitle: 'El programa es una manera simple de asegurarte dinero cada mes.',
    secondSubtitle:
      'Hacé tu primera compra y sumate a la experiencia de revender los productos de Mercado Pago.',
    steps: [
      {
        id: 1,
        image: 'step-one.png',
        description: '<strong>Inscribite</strong> haciendo click y sé parte del programa.',
      },
      {
        id: 2,
        image: 'step-two.png',
        description:
          '<strong>Comprá</strong> a precio mayorista y con envío gratis adonde vos elijas.',
      },
      {
        id: 3,
        image: 'step-three.png',
        description: '<strong>Salí</strong> a revender y ganá premios con cada venta.',
      },
    ],
  };
};
