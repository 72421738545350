// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');

const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

module.exports = (siteId) => {
  if (siteId === 'MLC') {
    const PRIMARY_COLOR = '#009EE3';

    return {
      title: 'Los lectores son la mejor opción para tus clientes',
      imageUrl: 'new-points-bg.png',
      benefits: [
        {
          type: 'point',
          icon: <FeedbackPositive24 color={PRIMARY_COLOR} />,
          description: 'Les permite cobrar con tarjeta de crédito y débito donde y cuando quieran.',
        },
        {
          type: 'point',
          icon: <FeedbackPositive24 color={PRIMARY_COLOR} />,
          description: 'No hay costos de arriendo ni mantenimiento.',
        },
        {
          type: 'point',
          icon: <FeedbackPositive24 color={PRIMARY_COLOR} />,
          description: 'No necesitan cuenta bancaria.',
        },
        {
          type: 'point',
          icon: <FeedbackPositive24 color={PRIMARY_COLOR} />,
          description: 'Pueden generar rendimientos con sus ventas.',
        },
        {
          type: 'point',
          icon: <FeedbackPositive24 color={PRIMARY_COLOR} />,
          description: 'Deciden cuándo tener su dinero disponible.',
        },
      ],
      cards: [
        {
          title: 'Los lectores aceptan estas tarjetas',
          text: 'Puedes ofrecer a tus clientes pagos en hasta 12 cuotas.',
          list: [
            {
              src: 'card1.png',
            },
            {
              src: 'card2.png',
            },
            {
              src: 'card3.png',
            },
            {
              src: 'card4.png',
            },
            {
              src: 'card5.png',
            },
            {
              src: 'card6.png',
            },
          ],
        },
      ],
    };
  }

  if (siteId === 'MLM') {
    return {
      title: 'Point es la mejor opción para tus clientes',
      imageUrl: 'new-points-bg.png',
      benefits: [
        {
          type: 'point',
          icon: <FeedbackPositive24 />,
          title: 'Sin renta mensual',
          description:
            'Se paga una tasa fija por venta y el dinero queda disponible al instante los 365 días del año.',
        },
        {
          type: 'point',
          icon: <FeedbackPositive24 />,
          title: 'Cuenta digital gratuita',
          description:
            'Cuenta de Mercado Pago para cobrar y retirar dinero sin necesidad de cuenta bancaria o RFC.',
        },
        {
          type: 'point',
          icon: <FeedbackPositive24 />,
          title: 'Préstamos inmediatos',
          description:
            'Al cobrar con Point, tu cliente accede a Mercado Crédito sin trámites y sin aval.',
        },
        {
          type: 'point',
          icon: <FeedbackPositive24 />,
          title: 'Transacciones seguras',
          description:
            'Los cobros con Point cumplen con los estándares de seguridad para evitar fraudes.',
        },
      ],
      secondTitle: 'Point acepta todas estas tarjetas',
      cards: [
        {
          title: 'Débito y credito',
          list: [
            { src: 'card1.png' },
            { src: 'card2.png' },
            { src: 'card3.png' },
            { src: 'card4.png' },
            { src: 'card6.png' },
            { src: 'card7.png' },
            { src: 'card8.png' },
          ],
        },
        {
          title: 'Vales',
          list: [{ src: 'card5.png' }, { src: 'card9.png' }],
        },
      ],
    };
  }

  return {
    title: 'Mercado Pago es la mejor opción para tus clientes',
    imageUrl:
      'https://http2.mlstatic.com/storage/pog-cm-admin/calm-assets/img_hero-mobile--5dd6039c.png',
    benefits: [
      {
        type: 'point',
        icon: <FeedbackPositive24 />,
        description: 'Les permite cobrar con tarjeta de crédito y débito donde y cuando quieran.',
      },
      {
        type: 'point',
        icon: <FeedbackPositive24 />,
        description: 'Pueden elegir la mejor opción para su negocio.',
      },
      {
        type: 'point',
        icon: <FeedbackPositive24 />,
        description: 'No tiene costos de alquiler ni mantenimiento.',
      },
      {
        type: 'point',
        icon: <FeedbackPositive24 />,
        description: 'Deciden cuándo tener su dinero disponible.',
      },
      {
        type: 'point',
        icon: <FeedbackPositive24 />,
        description: 'No necesitan cuenta bancaria.',
      },
      {
        type: 'point',
        icon: <FeedbackPositive24 />,
        description: 'Pueden generar rendimientos con sus ventas.',
      },
      {
        type: 'point',
        icon: <FeedbackPositive24 />,
        description: 'Pueden obtener un crédito.',
      },
    ],
    secondTitle: 'Point acepta todas estas tarjetas',
    cards: [
      {
        title: 'Débito',
        list: [
          {
            alt: 'Visa Débito',
            src: 'visa-debito.png',
          },
          {
            alt: 'Mastercard Débito',
            src: 'master-debito.png',
          },
          {
            alt: 'Maestro',
            src: 'maestro-debito.png',
          },
          {
            alt: 'Cabal Débito',
            src: 'cabal-debito.png',
          },
          {
            alt: 'Alimentar',
            src: 'alimentar.png',
          },
        ],
      },
      {
        title: 'Crédito',
        list: [
          {
            alt: 'Visa',
            src: 'visa-credito.png',
          },
          {
            alt: 'Mastercard',
            src: 'master-credito.png',
          },
          {
            alt: 'American Express',
            src: 'amex-credito.png',
          },
          {
            alt: 'Tarjeta Nranja',
            src: 'naranja-credito.png',
          },
          {
            alt: 'Nativa',
            src: 'nativa-credito.png',
          },
          {
            alt: 'Tarjeta Shopping',
            src: 'tarjeta-credito.png',
          },
          {
            alt: 'Cencosud',
            src: 'cencosud-credito.png',
          },
          {
            alt: 'Cabal',
            src: 'cabal-credito.png',
          },
          {
            alt: 'Argenard',
            src: 'argencard-credito.png',
          },
          {
            alt: 'Diners',
            src: 'dinners-credito.png',
          },
          {
            alt: 'Cordobesa',
            src: 'cordobesa-credito.png',
          },
          {
            alt: 'CMR Falabella',
            src: 'cmr-credito.png',
          },
          {
            alt: 'Tarjeta Walmart',
            src: 'walmart-credito.png',
          },
          {
            alt: 'Ahora 12',
            src: 'ahora-credito.png',
          },
          {
            alt: 'Previaje',
            src: 'previaje-credito.png',
          },
          {
            alt: 'Plan Z',
            src: 'planz-credito.png',
          },
          {
            alt: 'Sucrédito',
            src: 'sucredito-credito.png',
          },
        ],
      },
    ],
  };
};
