// i18nLocalesOverride:['es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const Image = require('nordic/image');
const Typography = require('@andes/typography');

const LevelItem = require('../../../../Molecules/ItemDetails');

const configs = require('./config');

const Levels = ({ devices, siteId, levelConfigs }) => {
  const { title, imageSrc, items } = configs(devices, levelConfigs);

  return (
    <>
      <div className={`levels__wrapper ${siteId?.toLowerCase() || ''}`}>
        <div className="levels">
          <div className="levels__content">
            <Typography component="h3" size="m" type="title">
              {title}
            </Typography>

            <div className="levels-image">
              <Image preload height={532} lazyload="off" src={imageSrc} width={510} />
            </div>

            {items.map((item) => (
              <LevelItem key={item.title} {...item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

Levels.defaultProps = {
  devices: [],
  devicesConfig: [],
  siteId: '',
};

Levels.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.any),
  devicesConfig: PropTypes.arrayOf({
    configs: PropTypes.arrayOf({
      device_activation_earning_amount: PropTypes.number,
    }),
  }),
  levelConfigs: PropTypes.shape({
    emprendedor: PropTypes.shape({
      frequent_purchases_earning_amount: PropTypes.number,
      frequent_purchases_device_quantity: PropTypes.number,
    }),
    profesional: PropTypes.shape({
      frequent_purchases_earning_amount: PropTypes.number,
      frequent_purchases_device_quantity: PropTypes.number,
    }),
  }),
  siteId: PropTypes.string,
};

module.exports = Levels;
