// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const { Typography } = require('@andes/typography');
const CarouselFree = require('@andes/carousel-free');

const { CarouselFreeSlide } = CarouselFree;

const CardDivider = require('../../../Atoms/CardDivider');
const DeviceCard = require('../../../Molecules/DeviceCard');

const configs = require('./config');

const Points = ({ devices, siteId, deviceType }) => {
  const { title, text } = configs(siteId);

  return devices?.length > 0 ? (
    <div className={`point-discounts-wrapper ${siteId?.toLowerCase()}`}>
      <div className="point-discounts-wrapper__bg">
        <div className="point-discounts-container">
          <CardDivider />
          <div className="point-discounts">
            <Typography component="h2" size="l" type="title">
              {title}
            </Typography>
            <Typography component="p" size="xl">
              {text}
            </Typography>

            <div className="point-discounts-content">
              {deviceType === 'mobile' ? (
                <CarouselFree>
                  {devices?.map((point) => (
                    <CarouselFreeSlide key={point.model}>
                      <DeviceCard device={point} siteId={siteId} />
                    </CarouselFreeSlide>
                  ))}
                </CarouselFree>
              ) : (
                devices?.map((point) => (
                  <DeviceCard key={point.model} device={point} siteId={siteId} />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

Points.defaultProps = {
  devices: [],
  siteId: 'MLA',
  deviceType: 'mobile',
};

Points.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  siteId: PropTypes.string,
  deviceType: PropTypes.string,
};

module.exports = Points;
