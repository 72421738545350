// i18nLocalesOverride:['es-CL']

const React = require('react');

const FeedbackPositive24 = require('@andes/icons/FeedbackPositive24');

const { formatterPrice } = require('../../../../../../services/utils/formatter');
const { getBetterActivationPrize } = require('../../../../../../services/utils/prizes');

module.exports = (devices, levelConfigs) => {
  const getDeviceProfit = (deviceId) => {
    if (!devices || devices.length === 0) {
      return formatterPrice(0);
    }

    const selectedDevice = devices?.find((device) => device?.id === deviceId) || {};
    const deviceProfit =
      selectedDevice?.suggested_retail_price - selectedDevice?.price_with_discount;

    return formatterPrice(deviceProfit || 0);
  };

  const getActivationPrize = (level) => {
    return formatterPrice(+getBetterActivationPrize(level?.configs) || 0);
  };

  return {
    title: 'Crece con los niveles del programa y accede a nuevos beneficios en cada nivel',
    imageSrc: 'levels.png',
    items: [
      {
        icon: <FeedbackPositive24 color="#009EE3" />,
        title: 'Nivel Emprendedor',
        bullets: [
          {
            text: `Revende lectores y gana hasta ${getDeviceProfit(
              101,
            )} por margen de ganancia de lector Smart y hasta ${getDeviceProfit(
              59,
            )} por lector Point Mini.`,
          },
          {
            text: `Gana hasta ${getActivationPrize(levelConfigs?.emprendedor)} con el`,
            link: {
              url: '#benefits',
              text: 'premio de activación',
            },
            secondaryText:
              ', por cada lector que logre cobrar un monto mínimo mensual establecido.',
          },
        ],
      },
      {
        icon: <FeedbackPositive24 color="#009EE3" />,
        title: 'Nivel Profesional',
        description: `Además de ganar hasta ${getDeviceProfit(
          101,
        )} por margen de ganancia de lector Point Smart y hasta ${getDeviceProfit(
          59,
        )} por lector Point Mini, puedes:`,
        bullets: [
          {
            text: 'Comprar lectores de forma ilimitada.',
          },
          {
            text: 'Ganar las veces que quieras el',
            link: {
              url: '#benefits',
              text: 'premio por compras frecuentes.',
            },
          },
          {
            text: `Gana hasta ${getActivationPrize(levelConfigs?.profesional)} con el`,
            link: {
              url: '#benefits',
              text: 'premio de activación',
            },
            secondaryText:
              ', por cada lector que logre cobrar un monto mínimo mensual establecido.',
          },
        ],
      },
    ],
  };
};
