// i18nLocalesOverride:['es-CL']

const React = require('react');

const { Text, Title } = require('@andes/typography');

const Header = () => {
  return (
    <div className="form__header">
      <Title className="bold" component="h2" size="l" type="title">
        Comienza a revender en sencillos pasos
      </Title>
      <Text component="p">
        1. Completa el formulario con tus datos para empezar el proceso de registro.
        <br />
        2. Crea tu cuenta en Mercado Pago, si ya tienes una, no es necesario crearla nuevamente.
      </Text>
    </div>
  );
};

module.exports = Header;
