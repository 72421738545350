// i18nLocalesOverride:['es-CL']

const { formatterPrice } = require('../../../../../../services/utils/formatter');

function findDevice(devices, id) {
  if (!devices || devices.length === 0) {
    return {
      price_with_discount: 0,
      suggested_retail_price: 0,
    };
  }

  return devices?.find((device) => device?.id === id);
}

module.exports = (i18n, devices) => {
  return {
    title: i18n.gettext('Preguntas frecuentes'),
    faqs: [
      {
        title: i18n.gettext('¿En qué consiste el Programa?'),
        description: i18n.gettext(
          'Como participante del programa, accedes a precios exclusivos para la compra de lectores Point Mini y lectores Smart, para luego revenderlos al precio de mercado sugerido. Además, puedes acceder al premio por compras frecuentes, si compras lectores durante dos meses seguidos.',
        ),
      },
      {
        title: i18n.gettext('¿Cómo y cuándo puedo empezar a comprar?'),
        description: i18n.gettext(
          'Ya mismo puedes registrarte y empezar a comprar tus lectores a través de la plataforma creada exclusivamente para nuestros Revendedores del programa.',
        ),
      },
      {
        title: i18n.gettext('¿Cuáles son los requisitos para revender lectores?'),
        description: i18n.jsx.gettext(
          'Para participar del Programa de Revendedores es necesario que cumplas con todas las condiciones previstas en los Términos y condiciones y reúnas los requisitos siguientes:{0}1. Ser una persona física mayor de dieciocho (18) años de edad.{0}2. Estar domiciliado en Chile.{0}3. Ser usuario de Mercado Pago.',
          {
            tags: {
              0: '<br />',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Cuándo recibiré el premio por compras frecuentes?'),
        description: i18n.jsx.gettext(
          `El pago se realiza el mes siguiente de haber cumplido la meta. Para el cálculo, se consideran los lectores comprados durante dos meses consecutivos.{0}Para verificar las ganancias recibidas por el premio por compras frecuentes, accede a tu Cuenta de Mercado Pago y ve a la sección Actividad.{0}Los pagos son nombrados como "Dinero de regalo".`,
          {
            tags: {
              0: '<br />',
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Cuál es el precio de mayoreo al que accedo para comprar lectores?'),
        description: i18n.jsx.gettext('Lector Point mini: {1}{0}Lector Point Smart: {2}', {
          tags: {
            0: '<br/>',
          },
          replacements: {
            1: formatterPrice(findDevice(devices, 59)?.price_with_discount),
            2: formatterPrice(findDevice(devices, 101)?.price_with_discount),
          },
        }),
      },
      {
        title: i18n.gettext('¿Dónde y a quién puedo vender?'),
        description: i18n.gettext(
          'Vende online o de forma presencial a tus conocidos, amigos, familiares, compañeros de trabajo, a los comercios de tu barrio, y emprendimientos de tu ciudad.',
        ),
      },
      {
        title: i18n.gettext('¿A qué precio debo vender los lectores?'),
        description: i18n.jsx.gettext(
          'El precio sugerido de reventa es:{0}- Point Mini: {1}{0}- Point Smart: {2}',
          {
            tags: {
              0: '<br/>',
            },
            replacements: {
              1: formatterPrice(findDevice(devices, 59)?.suggested_retail_price),
              2: formatterPrice(findDevice(devices, 101)?.suggested_retail_price),
            },
          },
        ),
      },
      {
        title: i18n.gettext('¿Qué características tiene cada lector?'),
        description: i18n.jsx.gettext(
          '{1}Point Mini:{2}{0}- Conectividad bluetooth al celular.{0}- Tecnología de cobro NFC, Chip y banda magnética.{0}- Larga duración de batería.{0}- Indicadores LED de funcionamiento y procesamiento.{0}{0}{1}Point Smart:{2}{0}- Sistema Android.{0}- Conexión 4G y Wifi.{0}- Tecnología Contactless, Chip, Banda y QR.{0}',
          {
            tags: {
              0: '<br />',
              1: '<strong>',
              2: '</strong>',
            },
          },
        ),
      },
    ],
  };
};
