// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const Image = require('nordic/image');
const Typography = require('@andes/typography');
const CarouselSnapped = require('@andes/carousel-snapped');

const { CarouselSnappedSlide } = CarouselSnapped;

const configs = require('./config');

const Timeline = ({ deviceType, siteId }) => {
  const config = configs(siteId);

  return (
    <div className={`steps-wrapper ${siteId?.toLowerCase()}`}>
      <Typography component="h2" size="l" type="title">
        {config.title}
      </Typography>

      {config.subtitle && (
        <div className="steps-wrapper__subtitle">
          <Typography component="p">{config.subtitle}</Typography>
          {config.secondSubtitle && <Typography component="p">{config.secondSubtitle}</Typography>}
        </div>
      )}

      <div className="steps">
        {deviceType === 'mobile' ? (
          <CarouselSnapped arrows={false}>
            {config.steps.map((item) => (
              <CarouselSnappedSlide key={item.id}>
                <div className="step-item">
                  {item.image && <Image src={item.image} />}
                  <div>
                    {siteId === 'MLM' && (
                      <Typography component="strong" size="xl">
                        {item.title}
                      </Typography>
                    )}
                    <Typography
                      component="p"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                      size="s"
                    />
                  </div>
                </div>
              </CarouselSnappedSlide>
            ))}
          </CarouselSnapped>
        ) : (
          config.steps.map((item) => (
            <div key={item.id} className="step-item">
              {item.image && <Image src={item.image} />}
              <div>
                {siteId === 'MLM' && (
                  <Typography component="strong" size="xl">
                    {item.title}
                  </Typography>
                )}
                <Typography component="p" dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

Timeline.propTypes = {
  deviceType: PropTypes.string,
  siteId: PropTypes.string,
};

Timeline.defaultProps = {
  deviceType: 'mobile',
  siteId: 'MLA',
};

module.exports = Timeline;
