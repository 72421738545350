// i18nLocalesOverride:['es-AR','es-MX','es-CL']

const React = require('react');
const PropTypes = require('prop-types');

const Image = require('nordic/image');
const Typography = require('@andes/typography');

const ItemDetails = require('../../../Molecules/ItemDetails');
const CardDivider = require('../../../Atoms/CardDivider');

const configs = require('./config');

const PointsInfo = ({ siteId }) => {
  const config = configs(siteId);

  return (
    <div className={`point-infos__wrapper ${siteId?.toLowerCase()}`}>
      <div className="point-infos__wrapper-container">
        <div className="point-infos">
          <div className="point-infos-content">
            <CardDivider />
            <Typography component="h3" size="l" type="title">
              {config.title}
            </Typography>
            <div className="point-image">
              <Image src={config.imageUrl} />
            </div>
            {config.benefits.map((item) => (
              <ItemDetails key={item.description} {...item} />
            ))}
          </div>
        </div>
        <div className="point-infos__cards">
          {config.secondTitle && (
            <Typography component="h5" size="xs" type="title">
              {config.secondTitle}
            </Typography>
          )}
          <div className="card__wrapper">
            {config.cards.map((item) => (
              <div key={item.title} className="card__wrapper-content">
                <Typography component="p">
                  {item.title}
                  {item?.text && <Typography component="span">{item.text}</Typography>}
                </Typography>
                <div className="card__items">
                  {item.list.map((flag) => (
                    <Image
                      key={flag.alt}
                      alt={flag?.alt || 'credit card'}
                      className="card-image"
                      src={flag.src}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

PointsInfo.defaultProps = {
  siteId: 'MLA',
};

PointsInfo.propTypes = {
  siteId: PropTypes.string,
};

module.exports = PointsInfo;
